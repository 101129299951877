
window.___gcfg = {
    lang: "en-GB",
    parsetags: "onload"
};

new WOW({ offset: 70 }).init();

$(document).ready(function () {




    if ($("#HomepageMarker").length !== 0) {
        $(".video__left").addClass("wow fadeIn").attr({ "data-wow-offset": "250", "data-wow-duration": "2s" });
		$(".left-call-today").addClass("wow fadeInUp").attr({ "data-wow-duration": "3.5s", "data-wow-delay": "0.3s" });
		if (window.matchMedia('(min-width:768px)').matches) {
			$(".header__logo").addClass("wow bounceInDown").attr({ "data-wow-duration": "1.5s", "data-wow-delay": "0.5s" }).css("display", "block");
		} else {
			$(".header__logo").css("display", "block");
		}
    } else {
        $(".header__logo").css("display", "block");
    }




    $(".toplinks__dropdown").each(function (index, value) {

        $(".toplinks__dropdown-link", this).each(function (index, value) {
            $(this).attr("data-wow-delay", index / 10 + "s");
            $(this).attr("data-wow-offset", 0);
            $(this).addClass("wow fadeIn");
        });

    });


    $("img.has-tooltip").tooltip({
        title: function () {
            return $(this).attr("alt");
        },
        placement: "auto"
    });

    $("a.has-tooltip").tooltip({
        placement: "auto"
    });


    function fixSizes() {
		$('.testimonials__link').sameHeight();
	}

	setTimeout(fixSizes, 300);

	$(window).on('resize',
		function() {
			setTimeout(fixSizes, 300);

		});


});

